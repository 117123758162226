import React from "react";
import Layout from "@/layouts";
import AppBanner from "@/components/AppBanner";
import { useTranslation } from "react-i18next";
import "@/styles/OurPeople.scss";

function OurPeople() {
  const { t } = useTranslation();
  const members = [
    {
      image: "images/ngan-1.png",
      name: "CEO - Ngan Nguyen",
    },
    {
      image: "images/viet-1.png",
      name: "CTO - Viet Pham",
    },
    {
      image: "images/tuan-1.png",
      name: "CFO - Tuan Dang",
    },
    {
      image: "images/kim-1.png",
      name: "Operations Director - Kim Tran",
    },
    {
      image: "images/bao-1.png",
      name: "Marketing Director - Bao Ngo",
    },
    {
      image: "images/giang-1.png",
      name: "Customer Success Director - Giang Pham",
    },
  ];
  return (
    <Layout>
      <div className="our-people-page">
        <AppBanner
          image="images/our-people-banner.png"
          heading="ourPeople.banner.heading"
          description="ourPeople.banner.description"
        />
        <div className="vector vector-1">
          <div
            className="section-vector"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "images/vector.png"
              })`,
            }}
          />
        </div>
        <div className="our-people-page__content">
          <div className="quote">
            <div className="quote__content">
              <span className="icon">
                <img
                  src={process.env.PUBLIC_URL + "images/quote.png"}
                  alt="quote"
                />
              </span>
              <p>{t("ourPeople.content.quote")}</p>
            </div>
          </div>
          <div className="people-cards">
            {members.map((member, index) => (
              <div
                className="people-card"
                key={index}><div class="people-card__image"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + member.image
                  })`,
                }}
              ></div>
                <div className="people-card__name">{member.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default OurPeople;
